import { PathVisualizer } from "./PathVisualizer";

function App() {
    return (
        <div className="App">
            <PathVisualizer />
        </div>
    );
}

export default App;
